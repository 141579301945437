<template>
	<div class="but_shop_box flex" v-loading.fullscreen.lock="fullscreenLoading">
		<!-- {{ content }} -->
		<div class="shop_length" v-if="$store.state.shopObj.length != 0">
			<p class="shop_length_title" @click.stop="shopFn">
				已选-资源列表: {{ $store.state.shopObj.length }}
			</p>
			<div class="shop_particulars_box" v-show="shopShow">
				<div class="shop_particulars_ct_box flex">
					<p>已选资源 {{ $store.state.shopObj.length }}个</p>
					<p>总金额:￥{{ totalMoney }}元</p>
				</div>
				<!-- <img :src=`../${img}` alt=""> -->
				<ul class="shop_list">
					<li v-for="(item, index) in $store.state.shopObj" :key="index" class="flex">
						<p class="title flex">
							<!-- <img :src="item.logo" alt="" /> -->
							<span>{{ item.title }}</span>
							<i class="delete" @click.stop="deleteFn(index)"></i>
						</p>

						<p class="price"><span class="price" v-show="item.check">{{ item.check ==
		'member_image_text_price'
		?
		'图文价格' : item.check ==
			'member_video_price' ? '视频价格' : item.check ==
				'direct_price' ? '直发价格' : item.check ==
					'member_forward_price' ? '转发价格' : item.check ==
						'member_headline_price' ? '头条价格' : item.check == 'ordinary_price' ? '普条价格' : ''
								}}</span>￥{{
		item.medium_price.toFixed(2) }}</p>

					</li>
				</ul>
				<div class="flex" style="justify-content: flex-end">
					<button class="close" @click.stop="shopShow = false">关闭</button>
				</div>
			</div>
		</div>

		<div class="price_box flex" v-if="$store.state.shopObj.length != 0">
			<p class="money" style="margin-right: 20px;">
				账户余额: <span>￥{{ $user_info.money }}</span>元
			</p>
			<p class="price">
				总价: <span>￥{{ totalMoney }}</span>元
			</p>

			<button v-if="$route.name == 'fill' || $route.name == 'Orfill'" class="preview" @click.stop="subFn1">
				预览文章并发布
			</button>
			<!-- <button v-if="$route.name == 'fill'" class="sub" @click.stop="subFn1">
				确认下单
			</button> -->
			<button v-if="$route.name == 'ManuscriptPreview'" class="sub" @click.stop="subFn2">
				确认下单
			</button>
			<!-- <button v-if="$route.name == 'ManuscriptDetails'" class="sub" @click.stop="subFn3">
				立即支付
			</button> -->
		</div>
		<div class="shop_length" v-if="$store.state.shopObj.length == 0">

		</div>
		<div class="price_box flex" v-if="$store.state.shopObj.length == 0">
			<button class="sub" @click.stop="subFn3">
				选择媒介
			</button>
		</div>
		<!-- 遮罩层 -->
		<div class="MaskLayer" @click="shopShow = !shopShow" v-show="shopShow"></div>
		<Popup @close="close" :visible="popupVisible" :content="popupContent" :id="$route.query.id" />
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Popup from '../components/sensitivityPopup.vue'
import { empty } from '../util/emptyFillDate'
import { fill_title_rules } from '@/util/fill_title_rules'
export default {
	name: '',
	computed: {
		...mapState(['shopObj', 'FillForm', 'ActiveChild', 'UserTypeItem', 'fillActive', 'mediaOrder']),
		totalMoney: function () {
			var n = 0;
			this.$store.state.shopObj.forEach((item, index) => {
				if (typeof (this.$store.state.shopObj[index].medium_price) === 'number') {
					n += this.$store.state.shopObj[index].medium_price
				} else {
					n += parseInt(this.$store.state.shopObj[index].medium_price)
				}
			})
			return n.toFixed(2);
		}
	},
	components: {
		Popup
	},
	mounted() {
	},

	data() {
		return {
			shopShow: false,
			fullscreenLoading: false,
			popupVisible: false,
			popupContent: [],
		}
	},
	methods: {
		// 更新用户信息
		setUserInfo() {
			this.curlGet('/api/user/info').then((res) => {
				if (res.data.code) {
					this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
					this.$user_info = res.data.data
					console.log(this.$user_info);
				}
			})
		},
		shopFn() {
			this.shopShow = true
		},
		// 预览
		subFn1() {
			// 效验标题状态
			const titleStatus = fill_title_rules(this.$store.state.FillForm.title)
			console.log(titleStatus);
			if (!titleStatus) {
				return
			}
			if (this.$store.state.FillForm.title == '') {
				return this.$message({
					type: 'warning',
					message: '请填写稿件标题',
				})
			}
			if (this.$store.state.FillForm.content == '' && this.$store.state.FillForm.url == '') {
				return this.$message({
					type: 'warning',
					message: '链接/内容须选填一项',
				})
			}
			this.$store.state.fillActive = true
			this.verifyTest()
		},
		subFn2() {

			if (this.$user_info.money - this.totalMoney < 0) {
				// this.$message({
				// 	type: 'warning',
				// 	message: '余额不足,请充值',
				// })
				this.$alert('<p style="color: #333;font-size: 1.1rem;">余额不足,<a style="color: #108cdd;font-weight: bold;text-decoration: none;" href="https://www.qixuantong.com/user/refill" target="_blank">点击跳转至充值</a></p><p>充值完成后可返回此页面继续提交订单</p>', '提示', {
					dangerouslyUseHTMLString: true,
					showCancelButton: true,
					confirmButtonText: '确认充值完成'
				}).then(res => {
					this.setUserInfo()
				})
			} else {
				this.fullscreenLoading = true
				let data = {}
				data.title = this.$store.state.FillForm.title
				data.content = this.$store.state.FillForm.content

				if (this.$store.state.FillForm.limit_time != '') {
					data.limit_time = this.$store.state.FillForm.limit_time / 1000
				}
				if (this.$store.state.FillForm.url != '') {
					data.url = this.$store.state.FillForm.url
				}
				if (this.$store.state.FillForm.file != '') {
					data.file = this.$store.state.FillForm.file
				}
				if (this.$store.state.FillForm.remark != '') {
					data.remark = this.$store.state.FillForm.remark
				}
				if (this.$store.state.FillForm.cover_image != '') {
					data.cover_image = this.$store.state.FillForm.cover_image
				}
				if (this.$store.state.FillForm.video_url != '') {
					data.video_url = this.$store.state.FillForm.video_url
				}
				if (this.$store.state.FillForm.original_url != '') {
					data.original_url = this.$store.state.FillForm.original_url
				}
				if (this.$store.state.ActiveChild != '套餐发布' || this.$store.state.ActiveChild == '草稿箱') {
					data.medium_ids = []
					for (let i = 0; i < this.$store.state.shopObj.length; i++) {
						data.medium_ids.push({
							id: this.$store.state.shopObj[i].id,
							type: this.$store.state.shopObj[i].check,
						})

					}
				} else if (this.$store.state.ActiveChild == '套餐发布' || this.$store.state.ActiveChild == '草稿箱') {
					data.package_ids = ''
					for (let i = 0; i < this.$store.state.shopObj.length; i++) {
						data.package_ids += `${this.$store.state.shopObj[i].id},`
					}
				}
				// 判断是否为发布媒介订单,如果是,则把data.medium_ids转成JSON
				if (data.medium_ids != []) {
					data.medium_ids = JSON.stringify(data.medium_ids)
				}
				if (this.$store.state.shopObj[0]) {
					data.category_id = this.$store.state.shopObj[0].category_id
				}
				console.log(data.medium_ids);
				this.curlPost('/api/users/order/add', data).then((res) => {
					console.log(res)
					if (res.data.code) {
						// 更新用户数据
						this.setUserInfo()
						this.$message({
							type: 'success',
							message: '支付成功',
						})
						this.$store.state.fillActive = true
						if (this.$store.state.ActiveChild != '套餐发布') {
							this.$store.commit('childTab', '媒介订单')
							this.$store.state.mediaOrder.category_id = data.category_id
							if (this.$store.state.FillForm.limit_time != '') {
								this.$router.push({
									path: '/user/MediaOrder',
									query: {
										time: true,
									}
								})
							} else {
								this.$router.push('/user/MediaOrder')
							}
						} else {
							this.$store.commit('childTab', '套餐订单')
							if (this.$store.state.FillForm.limit_time != '') {
								this.$router.push({
									path: '/user/PackageOrder',
									query: {
										time: true,
									}
								})
							} else {
								this.$router.push('/user/PackageOrder')
							}
						}
						setTimeout(() => {
							this.$store.state.FillForm = empty()
							this.$store.state.shopObj = []
							console.log('删除:', this.$store.state.shopObj);
						}, 1000)
					} else {
						this.$message({
							type: 'warning',
							message: res.data.msg,
						})
					}
					this.fullscreenLoading = false

				})

			}
		},
		// 删除
		deleteFn(index) {
			this.$store.state.shopObj.splice(index, 1)
			if (this.$store.state.shopObj.length == 0) {
				this.shopShow = false
			}
		},
		// 选择媒介
		subFn3() {
			const titleStatus = fill_title_rules(this.$store.state.FillForm.title)
			if (!titleStatus) {
				return
			}
			if (this.$store.state.FillForm.title == '') {
				return this.$message({
					type: 'warning',
					message: '请填写稿件标题',
				})
			}
			if (this.$store.state.FillForm.content == '' && this.$store.state.FillForm.url == '') {
				return this.$message({
					type: 'warning',
					message: '链接/内容须选填一项',
				})
			}
			this.$store.state.fillActive = false

			this.verifyTest()
		},
		// 关闭敏感词弹窗
		close() {
			this.popupVisible = false;
		},
		// 效验文章是否有敏感字
		verifyTest() {
			this.fullscreenLoading = true
			this.popupContent = []
			if (this.$store.state.FillForm.content) {
				this.curlGet('/api/index/website').then(res => {
					res.data.data.sensitive_word.forEach((item, index) => {
						if (this.$store.state.FillForm.content.indexOf(item) > 0) {
							this.fullscreenLoading = false
							this.popupContent.push(item)
							return this.popupVisible = true
						}
					})
				})
			}
			setTimeout(() => {
				this.fullscreenLoading = false
				if (this.popupContent.length == 0) {
					if (!this.$store.state.fillActive) {
						this.goMedia()
					} else {
						this.$router.push('/user/SetMenu/ManuscriptPreview')
					}


				}
			}, 800)
		},
		nullDate() {
			this.$store.state.FillForm = {
				cover_image: '', //封面
				title: '',
				content: '', //编译器内容
				limit_time: '', //时间选择器
				file: '',
				url: '',
				video_url: '',
				original_url: '', //原文链接
				remark: '',
				medium_ids: '',
				package_ids: '',
			}
			this.$store.state.shopObj = []
		},
		goMedia() {
			let id = 0
			if (this.$route.query.id) {
				id = this.$route.query.id
			} else {
				id = `${this.$store.state.FillForm.category_id}`
			}
			console.log(typeof (id));
			switch (id) {
				case '0':

					this.$router.push('/user/SetMenu/SetMenuList')
					break;
				case '1':
					this.$router.push('/user/userMedia/News')
					break;
				case '2':
					this.$router.push('/user/userMedia/UserWeMedia')
					break;
				case '3':
					this.$router.push('/user/userMedia/UserPaperMedia')
					break;
				case '4':
					this.$router.push('/user/userMedia/UserOfficialAccounts')
					break;
				case '5':
					this.$router.push('/user/userMedia/UserWb')
					break;
				case '6':
					this.$router.push('/user/userMedia/UserRedBook')
					break;
				case '7':
					this.$router.push('/user/userMedia/UserShortVideo')
					break;
				case '8':
					this.$router.push('/user/SetMenu/SetMenuList')
					break;
				default:
					break;
			}
		}
	},

}
</script>

<style lang=scss scoped>
::v-deep .el-message-box__message p p {}

::v-deep .el-message-box__message p p a {}

.but_shop_box {
	width: 88.5%;
	height: 7.33rem;
	background: #fff;
	z-index: 999;
	position: fixed;
	bottom: 0;
	right: 0;
	padding: 0 5rem 0 6rem;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.shop_length {
		color: #333;
		font-size: 1.5rem;
		transition: 0.3s;
		cursor: pointer;

		.shop_length_title {
			font-size: 1.4rem;
		}

		&:hover {
			.shop_length_title {
				color: #108cdd;
			}
		}

		.shop_particulars_box {
			width: 33.33rem;
			height: 41.67rem;
			background: url('../assets/user/setMenu/Dialog.png') no-repeat;
			background-size: 100%;
			border-radius: 0.4rem;
			position: absolute;
			top: -465px;
			left: 60px;
			z-index: 9999;
			padding: 1rem 1rem 3rem 1rem;
			box-sizing: border-box;

			.shop_particulars_ct_box {
				justify-content: space-between;
				height: 3rem;
				align-items: center;
				border-bottom: 0.17rem solid #c5e6fc;

				p {
					font-size: 1.22rem;
					color: #333333;
				}
			}

			.shop_list {
				height: 30rem;
				overflow-y: auto;
				border-bottom: 0.17rem solid #c5e6fc;
				margin-bottom: 1rem;

				li {
					padding: 1rem 0;
					box-sizing: border-box;
					align-items: center;
					justify-content: space-between;
					transition: 0.2s;

					&:hover {
						background: #ccebff;
						opacity: 0.6;

						.title {
							align-items: center;
							position: relative;

							img {
								width: 5.33rem;
							}

							span {
								color: #333;
								font-size: 1.2rem;
								font-weight: 400;
								margin-left: 1rem;
							}

							.delete {
								display: block;
								width: 2rem;
								height: 2rem;
								background: url('../assets/user/setMenu/delete.png') no-repeat;
								background-size: 100%;
								position: absolute;
								left: 25px;
								top: 0;
							}
						}
					}

					.title {
						align-items: center;
						position: relative;

						img {
							width: 5.33rem;
						}

						span {
							color: #333;
							font-size: 1.2rem;
							font-weight: 400;
							margin-left: 1rem;
						}

						.delete {
							display: none;
							width: 2rem;
							height: 2rem;
							background: url('../assets/user/setMenu/delete.png') no-repeat;
							background-size: 100%;
							position: absolute;
							left: 25px;
							top: 0;
						}
					}

					.price {
						color: #333;
						font-size: 1.22rem;
					}
				}
			}

			.close {
				border: 0;
				color: #fff;
				width: 6.67rem;
				height: 2.5rem;
				border-radius: 0.42rem;
				background: #108cdd;
				box-shadow: 0.17rem 0.17rem 0.33rem #7a7a7a;
				cursor: pointer;
			}
		}
	}

	.price_box {
		align-items: center;

		.price {
			color: #333333;
			font-size: 16px;

			span {
				font-size: 16px;
				color: #ff0000;
			}
		}

		.money {
			color: #333;
			font-size: 16px;

			span {
				font-size: 16px;
				color: #ff9600;
			}
		}

		.preview {
			width: 9rem;
			height: 2.92rem;
			border-radius: 0.42rem;
			background: #ff8400;
			color: #fff;
			border: 0;
			margin-left: 2rem;
			cursor: pointer;
		}

		.sub {
			margin-left: 2rem;
			border: 0;
			background: #108cdd;
			width: 10rem;
			height: 2.92rem;
			border-radius: 0.42rem;
			font-size: 1.17rem;
			color: #fff;
			cursor: pointer;
		}
	}
}

@media screen and (max-width: 1554px) {
	.but_shop_box {
		width: 82.7vw;
		height: 7.33rem;
		background: #fff;
		z-index: 999;
		position: fixed;
		bottom: 0;
		right: 0;
		padding: 0 5rem 0 6rem;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.shop_length {
			color: #333;
			font-size: 1.5rem;
			transition: 0.3s;
			cursor: pointer;

			&:hover {
				color: #108cdd;
			}
		}

		.price_box {
			align-items: center;

			.price {
				color: #333333;
				font-size: 16px;

				span {
					font-size: 16px;
					color: #ff0000;
				}
			}

			.money {
				color: #333;
				font-size: 16px;

				span {
					font-size: 16px;
					color: #fdd831;
				}
			}

			.preview {
				width: 9rem;
				height: 2.92rem;
				border-radius: 0.42rem;
				background: #ff8400;
				color: #fff;
				cursor: pointer;
			}

			.sub {
				margin-left: 2rem;
				border: 0;
				background: #108cdd;
				width: 10rem;
				height: 2.92rem;
				border-radius: 0.42rem;
				font-size: 1.17rem;
				color: #fff;
				cursor: pointer;
			}
		}
	}
}

.MaskLayer {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background: #333;
	opacity: 0.3;
	z-index: 999;
	cursor: pointer;
}
</style>