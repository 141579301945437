<template>
    <div v-if="visible" class="popup" @click.self="closePopup">
        <!-- 弹窗的内容 -->
        <div class="popup-content">
            <p class="popup_p">
                提示：该文章含有敏感词" <span v-for="(item, index) in content" :key="index">{{ item }}</span> "
            </p>
            <div class="flex" style="justify-content: flex-end;">
                <button class="up_box" @click.stop="closePopup">修改</button>
                <button class="close_box" @click="goManView">继续</button>
            </div>
        </div>
        <!-- 关闭按钮 -->
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
    computed: {
        ...mapState(['fillActive']),
    },
    props: {
        visible: {
            type: [Boolean, String],
            default: false
        },
        content: {
            type: Array,
            default: false
        },
        id: {
            type: [Boolean, String],
            default: false,
        }
    },
    methods: {
        closePopup() {
            this.$emit('close');
        },
        goManView() {
            if (!this.$store.state.fillActive) {
                switch (this.$props.id) {
                    case '0':
                        this.$router.push('/user/SetMenu/SetMenuList')
                        break;
                    case '1':
                        this.$router.push('/user/userMedia/News')
                        break;
                    case '2':
                        this.$router.push('/user/userMedia/UserWeMedia')
                        break;
                    case '3':
                        this.$router.push('/user/userMedia/UserPaperMedia')
                        break;
                    case '4':
                        this.$router.push('/user/userMedia/UserOfficialAccounts')
                        break;
                    case '5':
                        this.$router.push('/user/userMedia/UserWb')
                        break;
                    case '6':
                        this.$router.push('/user/userMedia/UserRedBook')
                        break;
                    case '7':
                        this.$router.push('/user/userMedia/UserShortVideo')
                        break;
                    case '8':
                        this.$router.push('/user/SetMenu/SetMenuList')
                        break;
                    default:
                        break;
                }

            } else {
                this.$router.push('/user/SetMenu/ManuscriptPreview')
            }
        }
    }
}
</script>

<style scoped lang="scss">
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background: #fff;
    width: 40rem;
    /* height: 6rem; */
    padding: 2rem 1rem 1rem 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    text-align: left;

    .popup_p {
        color: #333;
        font-size: 1.2rem;

        span {
            font-size: 1.2rem;
            border-right: 0.1rem solid #333;
            padding: 0 0.5rem;
            box-sizing: border-box;

            &:last-child {
                border-right: 0;
                padding-right: 0;
            }
        }
    }

    .up_box {
        padding: 0.8rem 2rem;
        box-sizing: border-box;
        background: #fff;
        color: #2A82E4;
        border: 0.1rem solid #aaa;
        border-radius: 0.2rem;
        cursor: pointer;
        margin-right: 1rem;

    }

    .close_box {
        padding: 0.8rem 2rem;
        box-sizing: border-box;
        background: #2A82E4;
        color: #fff;
        border-radius: 0.2rem;
        border: 0;
        cursor: pointer;
    }


}

.close-button {
    margin-top: 10px;
}
</style>